<template>
  <div class="home">
    <div class="img_box">
      <div
        v-infinite-scroll="load"
        class="infinite-list"
        style="overflow: auto"
      >
        <div class="toubu">
          <div class="top">
            <div class="fu">
              <div class="top-left">
                <img
                  src="../assets/37f6094f03abae9c1884ff7beea2195.png"
                  alt=""
                />
              </div>
              <div class="top-right">
                <div class="top-text"><a href="#sy">首页</a></div>
                <div class="top-text"><a href="#xd">我要下单</a></div>
                <div class="top-text"><a href="#js">平台介绍</a></div>
                <div class="top-text"><a href="#xw">新闻中心</a></div>
                <div class="top-text"><a href="#hz">供应商合作</a></div>
                <div class="top-text"><a href="#lx">联系我们</a></div>
              </div>
            </div>
          </div>
          <div class="center1">
            <div class="sy">
              <div class="sy-kong" id="sy"></div>
              <div class="top" v-if="fullWidth > 540">
                四大无忧&nbsp;&nbsp;保证服务体验
              </div>
              <div class="top1" v-else>四大无忧&nbsp;&nbsp;保证服务体验</div>
              <div v-show="fullWidth > 540">昆山供销电商平台</div>
              <div class="center">
                <div class="left">
                  <div class="text" v-if="fullWidth > 540">品质无忧</div>
                  <div class="text5" v-else>品质无忧</div>
                  <div class="txt1" v-if="fullWidth > 540">
                    优质食材源头直采，压缩中间流通环节，降低损耗，由田间地头农户手中直采。完善的食品安全管控体系，实行高标准严审核，细节化管理确保商品品质。
                  </div>
                  <div class="txt5" v-else>
                    优质食材源头直采，压缩中间流通环节 ...
                  </div>
                </div>
                <div class="left">
                  <div class="text" v-if="fullWidth > 540">选择无忧</div>
                  <div class="text5" v-else>选择无忧</div>

                  <div class="txt1" v-if="fullWidth > 540">
                    生鲜食材全品类覆盖，满足多样化需求。调料酒饮，你说我找，你要我都有，提供无限选择同城当日送达，满足客户特殊化需求。
                  </div>
                  <div class="txt5" v-else>
                    生鲜食材全品类覆盖，满足多样化需求 ...
                  </div>
                </div>
                <div class="left">
                  <div class="text" v-if="fullWidth > 540">价格无忧</div>
                  <div class="text5" v-else>价格无忧</div>

                  <div class="txt1" v-if="fullWidth > 540">
                    精简繁冗的中间渠道，降低中间商赚差价，降低商户供应链成本。食材新鲜低价直观透明，大大节省用户采购成本，省钱又省心。
                  </div>
                  <div class="txt5" v-else>
                    精简繁冗的中间渠道，降低中间商赚差价 ...
                  </div>
                </div>
                <div class="left">
                  <div class="text" v-if="fullWidth > 540">服务无忧</div>
                  <div class="text5" v-else>服务无忧</div>

                  <div class="txt1" v-if="fullWidth > 540">
                    一键下单送货上门，同时，依托精准大数据分析及优势资源，让每个昆山人民在家足不出户，足不出昆就享受到来自全国各地的生鲜和农产品。
                  </div>
                  <div class="txt5" v-else>
                    一键下单送货上门，同时，依托精准大 ...
                  </div>
                </div>
              </div>
            </div>
            <div class="xd">
              <div class="kong" id="xd"></div>
              <div class="top">我要下单</div>
              <div v-show="fullWidth > 540">昆山供销电商平台</div>
              <div class="center">
                <div class="center-center">
                  微信下单
                  <div>
                    <img src="../assets/gh_2e2956668880_258.jpg" alt="" />
                  </div>
                </div>
                <!-- <div class="center-center">
                  支付宝下单
                  <div>
                    <img
                      src="../assets//2021003156663508_square_8cm.png"
                      alt=""
                    />
                  </div>
                </div> -->
              </div>
            </div>
          </div>

          <div class="bottom"></div>
        </div>
        <div class="js">
          <div class="js-kong" id="js"></div>
          <div class="top" v-if="fullWidth > 540">昆山供销电商平台介绍</div>
          <div class="js-top" v-else>昆山供销电商平台介绍</div>
          <div v-show="fullWidth > 540">昆山供销电商平台</div>
          <div class="wai">
            <div class="left">
              <div class="center">
                <div class="js-text" v-if="fullWidth > 540">平台宗旨</div>
                <!-- <div>平台宗旨</div> -->

                <div v-if="fullWidth > 540" class="js-text">
                  平台主打提供昆山特产、昆山时令节气农产品、对口帮扶产品(新疆、陕西、阜阳、沐阳、贵州当地特产)、米面粮油、鲜肉蛋禽、新鲜水果、新鲜蔬菜、水产海鲜、一般超市商品等
                  <br />
                  （1）考察引进符合行业标准、统一包装风格、产品溯源可控的第三方货源渠道加盟，丰富产品线
                  <br />
                  （2）设立市级农产品展厅、区镇实体店、中通快递合作自提点部署线下销售网络
                  <br />
                  （3）整合昆山市内优质周边游资源，丰富完善品牌功能
                </div>
                <div v-else class="js-txt">
                  平台主打提供昆山特产、昆山时令节气...
                </div>
              </div>
              <div class="center">
                <div class="js-text" v-if="fullWidth > 540">平台理念</div>
                <!-- <div>品牌诞生</div> -->
                <div v-if="fullWidth > 540" class="js-text">
                  致力于为昆山地区的消费者提供高质量、价格优惠的商品和服务，同时也为本地的生产厂商和供应商提供一个直接和消费者交流的平台。作为一个社区型电商平台，我们力求兼顾线上线下的服务。消费者不仅可以在家通过手机或电脑轻松购物，还可以在附近的实体店铺享受到面对面的服务。同时，我们也支持线上购物后线下提货或退货，让消费者可以按照自己的需求自由选择购物方式。
                </div>
                <div v-else class="js-txt">
                  致力于为昆山地区的消费者提供高质量...
                </div>
              </div>
              <div class="center">
                <div class="js-text" v-if="fullWidth > 540">平台特色</div>
                <!-- <div>品牌诞生</div> -->
                <div v-if="fullWidth > 540" class="js-text">
                  以昆山本地的供应商和消费者群体为核心，利用先进的互联网技术，实现商品信息、交易、支付等全程在线化，提供全方位的电子商务服务。以高效率、大规模和低成本的优势，深入探索农产品电商化模式，将传统的供销渠道转变为线上交易，实现从农田到餐桌的全链条服务。其主要特色是本地化，代表的是新型的农村电商模式，能有效地解决本地农产品流通的问题，促进农民增收和新农业的发展。
                </div>
                <div v-else class="js-txt">
                  以昆山本地的供应商和消费者群体为核...
                </div>
              </div>
            </div>

            <div class="right">
              <img src="../assets/a71bd9c4fd81fec0cf64766de7dc8ec.png" alt="" />
            </div>
          </div>
        </div>
        <div class="xw">
          <div class="wx-kong" id="xw"></div>
          <div class="top" v-if="fullWidth > 540">新闻中心</div>
          <div class="top3" v-else>新闻中心</div>
          <div class="center">
            <div
              class="wx-center"
              v-if="fullWidth > 540"
              @click="tz('https://mp.weixin.qq.com/s/CicaPX0ykPO9cdV6jbgseA')"
            >
              <div class="span1">市供销社系统近期工作动态</div>
              <div class="span2">2023-11-10 18:00</div>
            </div>

            <div
              class="wx-center"
              v-else
              @click="tz('https://mp.weixin.qq.com/s/CicaPX0ykPO9cdV6jbgseA')"
            >
              <div class="xw-span1">市供销社系统近期工作动态</div>
              <div class="xw-span2">2023-11-10 18:00</div>
            </div>
            <div
              class="wx-center"
              v-if="fullWidth > 540"
              @click="tz('https://mp.weixin.qq.com/s/IsLQVTivnvlY4tIMGxTp-g')"
            >
              <div class="span1">
                “供销大集·滋补文化节”六安金寨专场农展会圆满结束
              </div>
              <div class="span2">2023-10-31 21:30</div>
            </div>

            <div
              class="wx-center"
              v-else
              @click="tz('https://mp.weixin.qq.com/s/IsLQVTivnvlY4tIMGxTp-g')"
            >
              <div class="xw-span1">“供销大集·滋补文化节”</div>
              <div class="xw-span2">2023-10-31 21:30</div>
            </div>
            <div
              class="wx-center"
              v-if="fullWidth > 540"
              @click="tz('https://mp.weixin.qq.com/s/poOAGSjSJxK2yQvd1uNpjA')"
            >
              <div class="span1">
                市供销总社赴上海市嘉定区供销社（新嘉集团）学习考察
              </div>
              <div class="span2">2023-10-31 21:30</div>
            </div>

            <div
              class="wx-center"
              v-else
              @click="tz('https://mp.weixin.qq.com/s/poOAGSjSJxK2yQvd1uNpjA')"
            >
              <div class="xw-span1">市供销总社赴上海市</div>
              <div class="xw-span2">2023-10-31 21:30</div>
            </div>
            <div class="wx-center" v-if="fullWidth > 540" @click="tz('https://mp.weixin.qq.com/s/WY08ACcafCPJxe0M7KvXhw')">
              <div class="span1">
                “供销大集·滋补文化节”六安金寨专场农展会即将火热开展
              </div>
              <div class="span2">2023-10-25 18:00</div>
            </div>

            <div
              class="wx-center"
              v-else
              @click="tz('https://mp.weixin.qq.com/s/WY08ACcafCPJxe0M7KvXhw')"
            >
              <div class="xw-span1">“供销大集·滋补文化节”</div>
              <div class="xw-span2">2023-10-25 18:00</div>
            </div>
          </div>
          <!-- <div>买生鲜&nbsp;&nbsp;就选昆鲜生</div> -->
        </div>
        <div class="hz">
          <div class="top" id="hz" v-if="fullWidth > 540">供应商合作</div>
          <div class="top4" id="hz" v-else>供应商合作</div>
          <div v-show="fullWidth > 540">昆山供销电商平台</div>
          <!-- <swiperCom class="swiperCom"></swiperCom>   -->
          <div class="mm">
            <carousel :list="list" :duration="1500" :autoPlay="true" />
            <!-- <swiper
            :modules="modules"
            :slides-per-view="3"
            :space-between="50"
            navigation
            :pagination="{ clickable: true }"
            :scrollbar="{ draggable: true }"
            @swiper="onSwiper"
            @slideChange="onSlideChange"
          >
          </swiper> -->
          </div>

          <div class="bottom">
            <div>
              <!-- <div class="left">
              <img
                src="../assets/1 (2).png"
                alt=""
                width="100"
              >
              <div :style="{'margin-top':'5px'}">关于我们</div>
              <div :style="{'margin-top':'5px'}" @click="tk1">《昆山供销电商平台用户服务条款》</div>
              <div :style="{'margin-top':'5px'}" @click="tk2">《昆山供销电商平台隐私政策》</div>
            </div> -->
              <!-- <div class="center">
              <div>客服18550917974</div>
            </div> -->
              <div class="right">
                <div>微信扫码 关注小程序</div>
                <img
                  src="../assets/gh_2e2956668880_258.jpg"
                  alt=""
                  :style="{ 'margin-top': '5px' }"
                />
              </div>
            </div>
          </div>
        </div>
        <div class="di">
          <hr />
          <div id="lx">
            <div>Copyright 2022 昆山市都市农副产品有限公司</div>

            <br />
            <div  :style="{ color: 'white' }">
              <span @click="xk">苏ICP备2022044865号-1</span>
              
              
                <img src="../assets/微信图片_20240816102215.png" alt=""  style="width: 10px; margin-left: 50px;" >
                苏公网安备32058302004358
             
            </div>

            <br />
            <div>联系电话：18550917974</div>
          </div>
        </div>
        <!-- <li v-for="i in count" :key="i" class="infinite-list-item">{{ i }}</li> -->
      </div>
    </div>

    <!-- <div class="img_box">

    </div> -->

    <!-- <div class="img_box">
      
    </div> -->
    <!-- <div class="img_box">
     
    </div> -->
    <!-- <div class="img_box">

    </div> -->
  </div>
</template>
<script>
import { ref } from "vue";
// import SwiperCom from "@/components/swiperCom/swiperCom.vue";
// import { Navigation, Pagination, Scrollbar, A11y } from 'swiper';
// import { Swiper, SwiperSlide } from 'swiper/vue/swiper-vue.js';
export default {
  // components: {
  //   SwiperCom,
  // },
  setup() {
    const list = [
      {
        id: 1,
        imgUrl:
          "https://ksgxs.oss-cn-hangzhou.aliyuncs.com/1/1/20230720/1681844138323058688.png",
      },
      {
        id: 2,
        imgUrl:
          "https://ksgxs.oss-cn-hangzhou.aliyuncs.com/1/1/20230720/1681844139065450496.png",
      },
      {
        id: 3,
        imgUrl:
          "https://ksgxs.oss-cn-hangzhou.aliyuncs.com/1/1/20230720/1681844139182891008.png",
      },
      {
        id: 4,
        imgUrl:
          "https://ksgxs.oss-cn-hangzhou.aliyuncs.com/1/1/20230720/1681844137500975104.png",
      },
      // {
      //   id: 5,
      //   imgUrl:
      //     "https://kachuangoss.oss-cn-wulanchabu.aliyuncs.com/1/1/20221118/1593430341671256064.png",
      // },
    ];

    return { list };
  },
  data() {
    return {
      fullWidth: document.documentElement.clientWidth,
      fullHeight: document.documentElement.clientHeight,
    };
  },
  created() {
    window.addEventListener("resize", this.handleResize);
  },
  beforeUnmount() {
    window.removeEventListener("resize", this.handleResize);
  },
  mounted() {
    this.handleResize();
    console.log(this.fullHeight);
    console.log(this.fullWidth);
  },
  methods: {
    tz(url) {
      location.href = url;
      // this.$router.push("/xq");
    },
    tk1() {
      this.$router.push("/tk1");
    },
    tk2() {
      this.$router.push("/tk2");
    },
    handleResize() {
      this.fullWidth = document.documentElement.clientWidth;
      this.fullHeight = document.documentElement.clientHeight;
    },
    xk() {
      window.open("https://beian.miit.gov.cn", "_blank");
    },
  },
};
</script>
<style lang="scss" scoped>
.home {
  //固定外层盒子，不受其他盒子影响
  //放在最底层
  // position: fixed;
  // top: 0;
  // left: 0;
  z-index: -1000;

  .img_box {
    background-image: url("../assets/1\ \(2\).png");
    background-size: 100% 100%;
    -moz-background-size: 100% 100%;
    -webkit-background-size: 100% 100%;
    position: absolute;
    // background-image: url(./assets/ash98-n8ely.jpg);
    background-size: cover;
    // background-repeat: no-repeat;
    //保证盒子始终在窗口中心
    background-position: center center;
    //避免窗口周围留白
    // width: 101%;
    height: 100%;
  }

  // .img_box:before {
  //   content: '';
  //   position: fixed;
  //   z-index: -1;
  //   top: 0;
  //   right: 0;
  //   bottom: 0;
  //   left: 0;
  //   background-image: inherit;
  //   -webkit-background-size: cover !important;
  //   -moz-background-size: cover !important;
  //   background: url("../assets/1\ \(2\).png") center 0 no-repeat;
  //   background-size: cover !important;
  // }

  // width: 100%;
  // height: 6000px;
  // background-size: 100% 100%;
  // background: url("../assets/4.jpg");
  // background-repeat: repeat-y;
}

// .center {
//   margin: 0 auto;
//   width: 100%;
//   height: 100%;
// }
.toubu {
  // height: 100%;
  // position: relative;
  // top:0
}

.top {
  margin: auto;
  width: 100%;
  height: 100px;
  text-align: center;

  // background-color: white;
  // margin-top: 10px;
  .fu {
    width: 100%;
    height: 100px;
    background-color: rgba($color: #000000, $alpha: 0.5);
    // margin-left: 200px;
    position: fixed;
    // width: 100%;
    z-index: 1000;
  }
}

.top img {
  width: 100px;
}

.top-left {
  float: left;

  img {
    // margin-top: 10px;
  }
}

.top-right {
  padding-top: 10px;
  padding-left: 100px;
  padding-right: 100px;
  // height: 120px;
  text-align: left;
  line-height: 60px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.top-text {
  margin-left: 50px;
  // float: left;
  color: white;

  a {
    width: 100%;
    height: 100%;
    color: white;
    text-decoration: none;
    white-space: nowrap;
  }
}

.top-text:hover {
  border-bottom: skyblue solid 3px;
}

.center1 {
  width: 80%;
  margin: 0 auto;

  .sy {
    height: 60 0px;
    text-align: center;
    color: white;

    .sy-kong {
      height: 100px;
    }

    .top {
      font-size: 40px;
      height: 60px;
    }

    .center {
      margin-top: 50px;
      display: flex;
      justify-content: space-between;
      flex-wrap: wrap;

      // flex: 2;
      .left {
        margin-top: 50px;
        margin-left: 50px;

        width: 45%;
        text-align: left;
      }

      .text {
        font-size: 25px;
      }

      .txt1 {
        font-size: 20px;
      }

      .txt5 {
        font-size: 50px;
      }
    }
  }

  .xd {
    height: 400px;
    text-align: center;

    .kong {
      height: 50px;
    }

    .top {
      font-size: 40px;
      height: 60px;
      // margin-top: 50px;
    }

    // margin-top: 150px;
    color: white;

    .center {
      width: 80%;
      height: 400px;
      // padding-top: 80px;
      // margin-top: 50px;
      margin-left: 160px;
      display: flex;
      flex-direction: row;
      justify-content: space-around;
    }

    .center-center {
      text-align: center;
      font-size: 25px;
    }

    img {
      margin-top: 25px;
      max-width: 150px;
    }
  }
}

.js {
  height: 800px;
  text-align: center;
  margin: auto;
  // margin-left: 300px;
  color: white;
  width: 80%;
  padding-top: 50px;

  .js-kong {
    height: 100px;
  }

  .top {
    font-size: 40px;
    height: 60px;
  }

  // .js-top{

  // }
  .center {
    padding: 30px;

    margin-top: 50px;
    margin-left: 50px;
    // width: 100%;
    text-align: left;

    .js-txt {
      font-size: 50px;
    }
  }

  .center:hover {
    background-color: rgba($color: #000000, $alpha: 0.5);
    border-radius: 10px;
  }

  .wai {
    display: flex;
    justify-content: space-between;

    .left {
      width: 80%;

      .js-text {
        font-size: 20px;
      }
    }

    .right {
      margin-left: 50px;
      margin-top: 180px;

      // height: 500px;
      img {
        max-width: 200px;
        // max-height: 200px;
      }
    }
  }
}

.xw {
  height: 800px;
  text-align: center;
  margin: auto;
  width: 80%;
  // margin-top: 50px;
  color: white;
  padding-top: 200px;

  .wx-kong {
    height: 100px;
  }

  .top {
    font-size: 40px;
    height: 60px;
  }

  // .top:hover {
  //   background-color: red;
  // }
  .center {
    margin-left: 50px;
    // z-index: 100;
    line-height: 50px;
    margin-top: 50px;
    height: 50px;

    .wx-center {
      z-index: 1000;
      height: 100px;
      line-height: 100px;
      // padding-top: 30px;
      margin-top: 30px;
      // background-color: red;
      border-bottom: solid white 1px;
    }

    .wx-center:hover {
      background-color: rgba($color: #000000, $alpha: 0.5);
      border-radius: 10px;
    }

    .span1 {
      float: left;
      font-size: 20px;
    }

    .span2 {
      float: right;
      font-size: 20px;
    }

    .xw-span1 {
      float: left;
      font-size: 50px;
    }

    .xw-span2 {
      float: right;
      font-size: 50px;
    }
  }
}

.hz {
  height: 700px;
  text-align: center;
  margin: 0 auto;
  // margin-top: 50px;
  color: white;
  // padding-top: 150px;
  width: 80%;

  .top {
    font-size: 50px;
    height: 60px;
  }

  .swiperCom {
    margin-top: 0px;
  }

  .bottom {
    // margin-top: -200px;
    display: flex;

    justify-content: center;
    // .left {
    //   margin-left: 100px;
    //   text-align: left;
    // }
    // .center {
    //   margin-left: 200px;

    //   text-align: left;
    // }
    .right {
      // margin-left: 200px;
      text-align: center;

      img {
        width: 150px;
      }
    }
  }
}

.di {
  margin-top: 150px;
  width: 100%;
  text-align: center;
  color: white;

  hr {
    background-color: darkgrey;
    // border: darkgrey 1 solid;
    color: darkgrey;
  }

  #lx {
    div {
      margin-top: 3px;
    }
  }
}

.mm {
  margin-left: 350px;
}

.infinite-list {
  height: 99.9%;
  padding: 0;
  margin: 0;
  list-style: none;
}

.infinite-list .infinite-list-item {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 50px;
  background: var(--el-color-primary-light-9);
  margin: 10px;
  color: var(--el-color-primary);
}

.infinite-list .infinite-list-item + .list-item {
  margin-top: 10px;
}
</style>
